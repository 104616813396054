import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { alpha, useTheme } from "@mui/material/styles";

import { bgGradient } from "theme/css";

import Logo from "components/logo";
import { useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";

import { getUserByToken, login } from "auth/core/_requests";
import { useAuth } from "auth/core/Auth";
import Iconify from "components/iconify";
import { TextInput } from "components/Inputs";
import { jwtDecode } from "jwt-decode";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

interface FormValues {
  username: string;
  password: string;
}

const LoginView: React.FC = () => {
  const theme = useTheme();
  const { control, handleSubmit, formState } = useForm<FormValues>();
  const { saveAuth, setCurrentUser } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const onSubmit = async ({ username, password }: FormValues) => {
    try {
      const response = await login(username, password);
      saveAuth(response.data);
      const { data: user } = await getUserByToken();
      user.auth = response.data;
      const decodedToken = jwtDecode(user.auth!.accessToken) as any;
      user.role = decodedToken.role;
      setCurrentUser(user);
      if (state && state.redirectTo) {
        window.history.pushState(null, "", "/");
        navigate(state.redirectTo);
        return;
      }
      navigate("/");

    } catch (error: any) {
      saveAuth(undefined);
      toast.error("שם משתמש או סיסמא שגויים");
    }
  };
  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.6),
          imgUrl: "/assets/background/overlay-1.png",
        }),
        height: 1,
      }}
    >
      <Logo
        sx={{
          position: "fixed",
          top: { xs: 16, md: 24 },
          left: { xs: 16, md: 24 },
        }}
      />
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            maxWidth: 800,
            minWidth: 420,
            maxHeight: 0.75,
            overflow: "auto",
          }}
        >
          <Box sx={{ maxWidth: 420 }}>
            <Typography variant="h4">התחברות</Typography>
            <Stack spacing={2} sx={{ mt: 3 }} component="form" onSubmit={handleSubmit(onSubmit)} noValidate >
              <TextInput
                fieldName="username"
                label="שם משתמש"
                control={control}
                rules={{
                  required: "יש להזין שם משתמש!",
                  maxLength: {
                    value: 50,
                    message: "שם המשתמש יכול להכיל עד 50 תווים",
                  },
                }}
              />
              <TextInput
                fieldName="password"
                label="סיסמא"
                control={control}
                type={showPassword ? "text" : "password"}
                rules={{ required: "יש להזין סיסמא!" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                id="btn-login"
                loading={formState.isSubmitting}
              >
                שליחה
              </LoadingButton>
            </Stack>
          </Box>
        </Card>
      </Stack>
    </Box>
  );
};

export default LoginView;
