import { Suspense, useEffect } from "react";
import "./global.css";
import { useScrollToTop } from "./hooks/use-scroll-to-top";
import Router from "./routes/sections";
import ThemeProvider from "./theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Toaster, toast } from "react-hot-toast";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { ConfirmProvider } from "material-ui-confirm";
import Tracker from "@openreplay/tracker";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAuth } from "auth";

const tracker = new Tracker({
  projectKey: "4qbO61SsYX4txibiYkLk",
  obscureInputNumbers: false,
  obscureTextEmails: false,
  obscureInputDates: false,
  obscureInputEmails: false,
  obscureTextNumbers: false,
  __DISABLE_SECURE_MODE: true,
  defaultInputMode: 0,
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

// ----------------------------------------------------------------------

export default function App() {

  const { currentUser } = useAuth();

  useScrollToTop();

  const setOnline = () => {
    toast.dismiss();
    toast.success("חזרת לאינטרנט!", { duration: 3000 });
  };

  const setOffline = () => {
    toast.error("אתה במצב לא מקוון. בדוק את החיבור שלך.", { duration: Infinity });
  };

  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  useEffect(() => {
    tracker.start();
  }, []);

  useEffect(() => {
    if (currentUser) {
      tracker.setUserID(currentUser.username);
    }
  }, [currentUser?.username, tracker]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="he">
      <CacheProvider value={cacheRtl}>
        <QueryClientProvider client={new QueryClient()}>
          <ThemeProvider>
            <Toaster position="top-center" reverseOrder={false} />
            <ConfirmProvider
              defaultOptions={{
                title: "האם אתה בטוח ?",
                confirmationText: "אישור",
                cancellationText: "ביטול",
              }}
            >
              <Suspense>
                <Router />
              </Suspense>
            </ConfirmProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </CacheProvider>
    </LocalizationProvider>
  );
}
